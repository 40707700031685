import React from 'react'
import { Link } from 'react-router-dom'

function HomeRecipeCard({ data }) {
    return (
        <Link to={`recipe/${data.id}`}>
            <div className='relative'>
                <div className='absolute bottom-0 w-full h-[100px] bg-gradient-to-b from-transparent to-gray-700 rounded-lg'></div>
                <img src={data.image} alt="" className='rounded-lg w-full' />
                <span className='absolute bottom-3 left-3 font-extralight text-sm text-white'>{data.title}</span>
            </div>
        </Link>
    )
}

export default HomeRecipeCard