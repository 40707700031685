import React, { useEffect, useState } from 'react'
import { SPOONACULAR_API_KEY } from '../utils/constants';
import HomeRecipeCard from '../component/HomeRecipeCard';

function Home() {
  const [searchQuery, setSearchQuery] = useState()
  const [searchResult, setSearchResult] = useState()

  useEffect(() => {
    let timer = setTimeout(async () => {
      if (searchQuery !== "" && searchQuery !== null) {
        let url = `https://api.spoonacular.com/recipes/complexSearch?query=${searchQuery}&apiKey=${SPOONACULAR_API_KEY}&number=20`;
        const response = await fetch(url);
        const result = await response.json();
        setSearchResult(result.results);
      } else {
        setSearchResult()
      }
    }, 500);

    return () => {
      clearTimeout(timer)
    }
  }, [searchQuery])

  return (
    <div>
      <div className='bg-gray-200 h-[200px] flex justify-center items-center p-10'>
        <input
        type="text"
        name="search"
        id="search"
        placeholder='Search keyword for recipe'
        className='text-gray-700 px-4 py-2 rounded-full w-full bg-transparent border-2 border-gray-300 md:w-[500px] lg:w-[500px]'
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}/>
      </div>
      <div className='grid grid-cols-1 items-center gap-10 p-10 md:grid-cols-3 lg:grid-cols-4'>
        {
          searchResult && searchResult.map(item => <HomeRecipeCard key={item.id} data={item} />)
        }
      </div>
    </div>
  )
}

export default Home