import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { SPOONACULAR_API_KEY } from '../utils/constants'

function Recipe() {
  const { id } = useParams()
  const [isLoading, error, data] = FetchRecipe(id)

  if (isLoading) {
    return (
      <div>Data Is Loading...</div>
    )
  }

  if (error) {
    return (
      <div>Something Unexpected Happened</div>
    )
  }

  return (
    <div>
      <div>
        <img src={data?.image} alt="" />
      </div>
    </div>
  )
}

export default Recipe

function FetchRecipe(id) {

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [data, setData] = useState()

  useEffect(() => {
    setIsLoading(true)
    fetch(`https://api.spoonacular.com/recipes/${id}/information?apiKey=${SPOONACULAR_API_KEY}`)
      .then(response => response.json())
      .then(result => {
        setData(result)
        console.log(result)
      })
      .catch((error) => {
        setError(true)
        console.log(error)
      })
    setIsLoading(false)
  }, [id])

  return [isLoading, error, data]
}