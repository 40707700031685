import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './page/Home';
import Recipe from './page/Recipe';

const appRoutes = createBrowserRouter([
  {
    path:"/",
    element:<Home/>
  },
  {
    path:"/recipe/:id",
    element:<Recipe/>
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <RouterProvider router={appRoutes}/>
  </StrictMode>
);
